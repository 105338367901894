svg.logo #Trunk {
  opacity: 0;
  animation:
    fadeIn 0.5s forwards,
    grow 0.5s forwards;
  transform-origin: 50% 100%;
}
svg.logo #First_x5F_1 {
  opacity: 0;
  animation:
    fadeIn 0.5s 0.3s forwards,
    grow 1s 0.3s forwards;
  transform-origin: 0% 0%;
}
svg.logo #First_x5F_2 {
  opacity: 0;
  animation:
    fadeIn 0.5s 0.3s forwards,
    grow 1s 0.3s forwards;
  transform-origin: 25% 100%;
}
svg.logo #First_x5F_3 {
  opacity: 0;
  animation:
    fadeIn 0.5s 0.3s forwards,
    grow 1s 0.3s forwards;
  transform-origin: 50% 100%;
}
svg.logo #First_x5F_4 {
  opacity: 0;
  animation:
    fadeIn 0.5s 0.3s forwards,
    grow 1s 0.3s forwards;
  transform-origin: 100% 0%;
}
svg.logo #second_x5F_1 {
  opacity: 0;
  animation:
    fadeIn 0.5s 0.7s forwards,
    grow 1s 0.7s forwards;
  transform-origin: 50% 100%;
}
svg.logo #second_x5F_2 {
  opacity: 0;
  animation:
    fadeIn 0.5s 0.7s forwards,
    grow 1s 0.7s forwards;
  transform-origin: 0% 50%;
}
svg.logo #second_x5F_3 {
  opacity: 0;
  animation:
    fadeIn 0.5s 0.7s forwards,
    grow 1s 0.7s forwards;
  transform-origin: 100% 60%;
}
svg.logo #second_x5F_4 {
  opacity: 0;
  animation:
    fadeIn 0.5s 0.7s forwards,
    grow 1s 0.7s forwards;
  transform-origin: 50% 100%;
}
svg.logo #second_x5F_5 {
  opacity: 0;
  animation:
    fadeIn 0.5s 0.7s forwards,
    grow 1s 0.7s forwards;
  transform-origin: 0% 60%;
}
svg.logo #second_x5F_6 {
  opacity: 0;
  animation:
    fadeIn 0.5s 0.7s forwards,
    grow 1s 0.7s forwards;
  transform-origin: 100% 70%;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes grow {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
